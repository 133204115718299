@media only screen and (min-width: 993px) {
  .mobile-only {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .mobile-only {
    display: block;
  }
  div.headerImage {
    display: none;
    margin-bottom: 30px;
  }
  div.headerImage img {
    max-width: 100%;
    height: auto;
  }
  div.clientCard {
    margin-bottom: 5px;
  }
}
h1.homepageTitle {
  margin-bottom: 50px;
}
div.loginCards {
  display: flex;
  align-items: stretch;
  margin-bottom: 30px;
}
div.headerImage {
  margin-bottom: 30px;
}
div.headerImage img {
  max-width: 100%;
  height: auto;
}
