#header-sub-nav-main ul.menu {
  position: relative;
}
#header-sub-nav-main ul.menu li a.is-active,
#header-sub-nav-main ul.menu li.has-children a.is-active {
  background-color: #7B747B;
  color: #fff;
}
#header-sub-nav-main ul.menu li:hover,
#header-sub-nav-main ul.menu li.has-children:hover {
  color: black;
}
#header-sub-nav-main ul.menu li:hover a,
#header-sub-nav-main ul.menu li.has-children:hover a {
  color: black;
  background-color: #fff;
  outline-color: #000;
  outline-offset: -2px;
  outline-style: solid;
  outline-width: 1px;
}
#header-sub-nav-main ul.menu li:hover a.is-active,
#header-sub-nav-main ul.menu li.has-children:hover a.is-active {
  color: black;
}
#header-sub-nav-main ul.menu li:hover ul,
#header-sub-nav-main ul.menu li.has-children:hover ul {
  display: block;
  outline-color: #000;
  outline-offset: -1px;
  outline-style: solid;
  outline-width: 1px;
}
#header-sub-nav-main ul.menu li:hover ul li a,
#header-sub-nav-main ul.menu li.has-children:hover ul li a {
  outline: none;
  display: block;
  padding: 5px 25px 5px 10px;
  text-decoration: none;
  font-size: 14px;
}
#header-sub-nav-main ul.menu li:hover ul li a:hover,
#header-sub-nav-main ul.menu li.has-children:hover ul li a:hover {
  background-color: #8d8b8c;
  color: #fff;
}
#header-sub-nav-main ul.menu li ul,
#header-sub-nav-main ul.menu li.has-children ul {
  display: none;
  position: absolute;
  background-color: #fff;
  margin: 0;
  padding: 0px ;
  width: max-content;
}
#header-sub-nav-main ul.menu li ul li a,
#header-sub-nav-main ul.menu li.has-children ul li a {
  display: block;
  text-decoration: none;
  color: #AAA;
}
#header-sub-nav-main ul.menu li ul li:hover,
#header-sub-nav-main ul.menu li.has-children ul li:hover {
  color: #FFF;
  background-color: #7B747B;
}
