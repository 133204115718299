.userCard {
  border: solid 1px black;
  height: calc(100% - 30px);
  margin: 15px 0 15px 0!important;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
}
.userCard .redColor {
  color: red;
}
.userCard .row {
  margin-top: 10px;
}
.userCard .row.commentsRow {
  flex: 1;
}
.userCard .row.AdminCommentsRow {
  border-top: dashed 1px black;
  padding-top: 10px;
}
.userCard .row.AdminCommentsRow .adminCommentsTitle {
  color: blue;
}
.userCard .row ul {
  list-style-type: disc;
  margin-left: 0px;
}
.userCard .row ul li {
  margin: 0;
}
.userCard .row.userHeader {
  margin-top: 0px;
  padding: 5px 10px;
  background-color: #4b4b4b;
  color: white;
}
.userCard .row.userHeader.selected {
  background-color: #e30513;
  color: white;
}
.userCard .row.userHeader .userName {
  font-weight: bold;
  display: block;
  width: 100%;
}
.userCard .row.userHeader .userFaculty {
  font-style: italic;
  display: block;
  width: 100%;
}
.userCard .row.userHeader .userCountry {
  display: block;
  width: 100%;
}
.userCard .row.userHeader .userRegisteredOn,
.userCard .row.userHeader .userAvailableOn,
.userCard .row.userHeader .firstSession {
  display: block;
  width: 100%;
  font-size: 14px;
  font-style: italic;
  text-align: right;
}
.userCard .row.userHeader .userRegisteredOn *,
.userCard .row.userHeader .userAvailableOn * {
  margin-left: 5px;
  font-weight: bold;
}
.userCard .row.userHeader div.sponsorLoad {
  display: block;
  font-size: 12px;
  background-color: #cccccc;
  color: #4b4b4b;
  width: 60px;
}
.userCard .row.userHeader div.sponsorLoad span.slashBar {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  padding: 0 1px;
}
.userCard .row.userHeader div.sponsorLoad span.valueCurrentLoad {
  font-size: 20px;
}
.userCard .row.userHeader div.sponsorLoad span.valueCurrentLoad.overLoaded {
  color: red;
}
.userCard .row.userHeader div.sponsorLoad span.totalCurrentLoad {
  font-size: 20px;
}
.userCard .row .sponsorLoadNames {
  margin-top: 5px;
  margin-left: 5px;
  padding: 7px 15px;
  font-size: 12px;
}
.userCard .row.userFooter {
  padding: 5px 10px;
  background-color: #cccccc;
  text-align: right;
}
.userCard .row.userFooter.selected {
  background-color: #ffc103;
  color: black;
}
.userCard .row.userFooter .userDetailsData {
  font-size: 85%;
}
.userCard .row.userFooter .badge .userScoreTitle {
  background-color: white;
}
.userCard .row.userFooter .userScore {
  color: white;
  background-color: #e30513;
  border-radius: 5px;
  padding: 2px 5px;
  margin: 0 5px;
}
.userCard .row.userFooter .scoreDisplay {
  margin-top: 5px;
}
.userCard .icon {
  width: 45px;
  height: 45px;
  margin-bottom: 5px;
  background-size: contain;
  background-position: center;
  border-radius: 8px;
  margin-top: 5px;
}
.userCard .icon.genderIconMale {
  background: url("images/GenderM-White2.png") #3F33FF no-repeat;
  background-size: 100% 100%;
}
.userCard .icon.genderIconFemale {
  background: url("images/GenderF-White2.png") #FF33C4 no-repeat;
  background-size: 100% 100%;
}
.userCard .icon.genderIconUnset {
  background: url("images/GenderO-White2.png") #33cc33 no-repeat;
  background-size: 100% 100%;
}
.userCard .icon.veteranIcon {
  background: url("images/Veteran.png") #33cc33 no-repeat;
  background-size: 100% 100%;
}
.userCard .icon.langIcon {
  background: url("images/Language.png") no-repeat;
}
.userCard .icon.prefsIcon {
  background: url("images/Preferences.png") no-repeat;
}
.userCard .icon.interestsIcon {
  background: url("images/Interests.png") no-repeat;
}
.userCard .icon.commentsIcon {
  background: url("images/Interests.png") no-repeat;
}
.userCard .rowTitle {
  padding-right: 5px;
  font-weight: bold;
}
