.collecteRenseignementsPerso {
  background-color: #f1f1f1;
  padding: 30px 40px;
  margin-bottom: 20px;
  margin-top: 120px;
  font-weight: 400;
  color: #555555;
}
.collecteRenseignementsPerso p {
  margin-top: 10px;
}
.collecteRenseignementsPerso a {
  color: #3470B5;
}
.submit-btn {
  margin-top: 30px;
  margin-bottom: 80px;
}
