.selectAllButton {
  margin: 20px 0;
}
div.menuActionButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
div.menuActionButtons .btn {
  display: block;
  align-self: center;
}
ul.pagination li {
  list-style-type: none;
}
