.Toastify__toast-container {
  width: 400px;
}
.Toastify__toast--error {
  color: white!important;
  background-color: #C84243 !important;
}
.Toastify__toast--error div.ToastStdMessage {
  display: flex;
}
.Toastify__toast--error div.ToastStdMessage .Block-Icon {
  display: inline-flex;
  padding: 10px 20px 10px 10px;
}
.Toastify__toast--error div.ToastStdMessage .Block-Icon i {
  font-size: 30px;
  color: white;
}
.Toastify__toast--error div.ToastStdMessage .Block-Content {
  display: inline-flex;
  flex-direction: column;
}
.Toastify__toast--error div.ToastStdMessage .Block-Content .title {
  font-family: "Source Sans Pro";
  font-size: 17px;
  font-weight: bold;
  border-bottom: solid 1px white;
  padding-bottom: 5px;
}
.Toastify__toast--error div.ToastStdMessage .Block-Content .content {
  font-family: "Source Sans Pro";
  font-size: 17px;
  font-weight: normal;
  line-height: 18px;
  margin: 10px 0;
}
