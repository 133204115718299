.containerfilterSelectionMenuBox {
  border: solid 1px black;
  padding-bottom: 20px;
}
.containerfilterSelectionMenuBox input.limitSearchResults {
  width: 50px;
}
.containerfilterSelectionMenuBox:not(:first-child) {
  padding: 0 10px;
}
.containerfilterSelectionMenuBox > .row:not(:first-child) {
  padding-left: 10px;
  padding-top: 5px;
}
.containerfilterSelectionMenuBox .row:not(:first-child) {
  padding-top: 5px;
}
.containerfilterSelectionMenuBox .filterSelectionMenuTitle {
  background-color: #4b4b4b;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}
.containerfilterSelectionMenuBox .filterSelectionMenuFooter {
  margin-top: 20px;
}
div.currentSelected {
  border: solid 1px black;
  margin-right: 10px;
}
div.currentSelected .cardTitle {
  padding: 5px 10px;
  background-color: #ce0202;
  color: #fff;
  text-align: center;
}
div.currentSelected .cardData {
  padding: 10px;
}
div.currentSelected .cardData .nameSelectedSponsor {
  display: block;
  width: 100%;
  font-weight: bold;
}
div.currentSelected .cardData .nameSelectedSponsorFaculty {
  display: block;
  width: 100%;
  font-style: italic;
}
