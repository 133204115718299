div.InfoBoxContent {
  background-color: #fff;
  color: #000;
  padding: 25px;
  margin-bottom: 30px;
  position: relative;
  display: block;
}
div.InfoBoxContent div.Block-Content {
  display: flex;
  flex-direction: column;
}
div.InfoBoxContent div.Block-Content .title {
  margin-bottom: 10px;
  color: #000;
  font-weight: bold;
  display: block;
  text-align: left;
  text-transform: uppercase;
}
div.InfoBoxContent div.Block-Content div.content {
  text-align: left;
}
div.InfoBoxContent div.Block-Content div.content p {
  margin-bottom: 10px;
}
div.InfoBoxContent div.Block-Content div.Block-Icon {
  margin-top: 20px;
  text-align: left;
}
div.InfoBoxContent div.Block-Content div.Block-Icon a.linkIcon {
  background-color: #000;
  color: #fff;
  padding: 4px 20px;
  font-size: 19px;
  text-decoration: none;
}
div.InfoBoxContent div.Block-Content div.Block-Icon a.linkIcon i {
  font-weight: bold;
}
div.InfoBoxContent div.Block-Content div.Block-Icon a.linkIcon i + span {
  margin-left: 5px;
}
div.InfoBoxContent div.Block-Content div.Block-Icon a.linkIcon span {
  font-size: 16px;
  margin-right: 5px;
  position: relative;
  bottom: 2px;
}
