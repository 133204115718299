div.searchMatchFooter{
  margin-left: 5px;
  padding-top: 5px;
  padding-bottom: 30px;
}

ul.matchFiles{

  li{
    list-style-type: none;
  }
}
