label.dateOnlyLabel{
    margin-top:5px;
}
span.dateLeftSideLabel{
    text-align: left;
    margin-left:10px;
    display:none;
}
span.dateLeftSideLabel.displayDateTitle{
    display: block;

}
label.dateOnlyLabel.displayDateTitle{
    margin-top:28px;
}
div.dateField{
    display:inline-block;
}

