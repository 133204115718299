.fr-box{
  z-index: 0;
}

.WarningMessageEnabled{
  color:white;
  background-color: red;
  border:solid 5px white;
  padding: 15px 20px;
  display: block;
  text-align: center;
}
.WarningMessageDisabled{
  display: none;
}


.collecteRenseignementsPerso{
  background-color: rgb(241, 241, 241);
  padding: 30px 40px;
  margin-bottom: 20px;
  margin-top:120px;
  font-weight: 400;
  color: rgb(85, 85, 85);

  p{
    margin-top: 10px;
  }
  a{
    color: #3470B5;
  }
}

.matchedBox{
  display:flex;
  background-color: cadetblue;
  color:white;
  margin-bottom: 15px;
  padding: 10px 15px;
  border: solid 5px white;

  .sponsoredImage{
    display:inline-flex;
    width: 30px;
    height: 30px;
    margin-right:10px;
  }
  p {
    vertical-align: middle;
    line-height: 30px;
    display:inline-flex;

    div{
      font-weight: bold;
      margin-left:10px;
    }
  }

}


.submit-btn{
  margin-top:30px;
  margin-bottom: 80px;
}