.ageIcons {
  width: 20px;
  float: left;
  position: relative;
  top: -5px;
  left: -10px;
}
.candidateApproved {
  background-color: yellowgreen!important;
  color: #000000;
}
.candidateRefused {
  background-color: red!important;
  color: #ffffff;
}
.candidateRefused:hover {
  color: #ffffff !important;
}
.candidateRefused a {
  font-weight: bold;
  color: #ffffff !important;
}
.candidateDesisted {
  background-color: rgba(230, 153, 128) !important;
  color: #000000;
}
.candidateNoFormation {
  background-color: yellow!important;
  color: #000000;
}
.candidateToBeChecked {
  background-color: dodgerblue!important;
  color: #000000;
}
.candidateConfirmed {
  background-color: #4b4b4b !important;
  color: #ffffff;
}
.candidateConfirmed a {
  color: #fff;
}
.candidateConfirmed:hover {
  color: #ccc !important;
}
.candidateConfirmed:hover a {
  color: #ccc;
}
