.ageIcons{
  width:20px;
  float:left;
  position: relative;
  top: -5px;
  left:-10px;
}

.candidateNew{

}
.candidateApproved{
  background-color: yellowgreen!important;
  color:rgba(0,0,0, 1);
}
.candidateRefused{
  background-color: red!important;
  color:rgba(255,255,255, 1);
  &:hover{
    color:rgba(255,255,255, 1) !important;
  }
  a{
    font-weight: bold;
    color:rgba(255,255,255, 1) !important;
  }
}
.candidateDesisted{
  background-color: rgba(230,153,128)!important;
  color:rgba(0,0,0, 1);
}
.candidateNoFormation{
  background-color: yellow!important;
  color:rgba(0,0,0, 1);
}
.candidateToBeChecked{
  background-color: dodgerblue!important;
  color:rgba(0,0,0, 1);
}
.candidateConfirmed{
  background-color: #4b4b4b!important;
  color:rgba(255,255,255, 1);
  a{
    color:#fff;
  }
  &:hover{
    color:#ccc!important;
    a{
      color:#ccc;
    }
  }
}