.footer {
  z-index: 0;
}
.footer-utils {
  text-align: right!important;
  max-width: 100%!important;
}
@media only screen and (max-width: 992px) {
  span.contact-row {
    overflow-wrap: break-word;
    word-break: break-all;
  }
  .footer-query {
    display: block;
    width: 60%;
    margin: 0 auto;
  }
  .footer-contact-inner--right {
    /* Réinitialisez les styles ici */
    display: initial;
    justify-content: initial;
    align-items: initial;
    flex-direction: initial;
  }
  .footer-emergency {
    text-align: center;
  }
}
