.collecteRenseignementsPerso{
  background-color: rgb(241, 241, 241);
  padding: 30px 40px;
  margin-bottom: 20px;
  margin-top:120px;
  font-weight: 400;
  color: rgb(85, 85, 85);

  p{
    margin-top: 10px;
  }
  a{
    color: #3470B5;
  }

}


.submit-btn{
  margin-top:30px;
  margin-bottom: 80px;
}