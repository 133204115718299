div.BlackBoxContent{
  background-color: #000;
  color:#fff;
  padding:25px;
  margin-bottom: 30px;
  position: relative;
  height: 100%;


  div.Block-Content{
    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .title{
      padding:0 0 15px 0;
      font-weight: bold;
      display: block;
      margin: 0 auto;
      text-align: center;
    }
    div.content{
      text-align: center;
      margin-top: 10px;
      margin-bottom: auto;

      p{margin-bottom: 10px;}

    }

    div.Block-Icon{
      text-align: center;
      margin-top: auto;

      a.linkIcon{
        background-color:#e30513 ;
        color:#fff;
        padding:4px 8px;
        font-size:19px;
        text-decoration: none;

        i{
          font-weight: bold;
          &+span{
            margin-left:5px;
          }
        }
        span{
          font-size:16px;
          margin-right:5px;
          position: relative;
          bottom:2px;
        }

      }
    }


  }




}