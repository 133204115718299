div.emailTagList{

  p{

  }

  ul.tagsUL{
    column-count: 4;
    border:dashed 1px black;
    padding:10px 20px;

    li{
      font-size: small;
      list-style-type: none;
      margin:0;
    }

  }

  label {
    font-weight: bold;
    font-size: 27px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

}

