@desktop:   ~"only screen and (min-width: 993px)";
@tablet:    ~"only screen and (max-width: 992px)";

div.GreyBoxContent{
  background-color: #d9d9d9;
  color:#000;
  padding:25px;
  margin-bottom: 30px;
  position: relative;
  display: block;

  div.Block-Content{
    display: flex;
    flex-direction: column;

    .title{
      margin-bottom: 10px;
      font-weight: bold;
      display: block;
      text-align: left;

      &.redTitle{
        color:#c20208;
      }
      &.blackTitle{
        color: #000;
      }


    }
    div.content{
      text-align: left;

      p{margin-bottom: 10px;}

    }

    div.Block-Icon{
      margin-top:20px;
      text-align: left;

      a.linkIcon {
        background-color:#111;
        color:#fff;
        margin-bottom: 10px;
        text-decoration: none;
        font-size:19px;
      }

      @media @tablet {
        a.linkIcon {
          display: block;
          padding: 7px 7px 11px 15px;
          width: 70%;
          margin-bottom: 10px;
        }
        span{
          font-size:16px;
          position: relative;
        }
      }

      @media @desktop {
        a.linkIcon{
          padding:4px 20px;
          margin-right: 10px;

          i{
             font-weight: bold;
             &+span{
               margin-left:5px;
             }
           }
          span{
            font-size:16px;
            margin-right:5px;
            position: relative;
            bottom:2px;
          }

        }
      }

    }

  }
}

