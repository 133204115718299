/* Basic editor styles */
.tiptap{
  border:1px solid black;

}
.tiptap, .tiptapPreview {
  > * + * {
    margin-top: 0.75em;
  }

  p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  margin:10px 0;
  padding: 5px 10px;

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3 {
    margin-top:0!important;
    margin-bottom:5px!important;
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    padding-top:3px;
    padding-bottom:3px;
    border-left: 2px solid #656565;
    background-color: #f0f0f0;
    color:#656565;

    p{
      margin-bottom:0!important;
    }
  }

  hr {
    border: none;
    border-top: 2px solid #656565;
    margin: 2rem 0;
  }
}