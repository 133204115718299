.TiptapToolbarWrapper {

  ::-webkit-scrollbar {
    width: 14px;
    height: 14px
  }

  ::-webkit-scrollbar-track {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: transparent
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0,0,0,0);
    background-clip: padding-box;
    border-radius: 8px;
    background-color: #0000
  }

  :hover::-webkit-scrollbar-thumb {
    background-color: #0000001a
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #00000026
  }

  ::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent
  }

  button,input,select {
    font-size: inherit;
    font-family: inherit;
    color: #000;
    margin: .1rem;
    border: 1px solid black;
    border-radius: .3rem;
    padding: .1rem .4rem;
    background: white;
    accent-color: black
  }

  button[disabled],input[disabled],select[disabled] {
    opacity: .3
  }

  button.clearOutlinedButton{
    background-color:red;
    color:white;
  }

  .tiptap:focus {
    outline: none
  }

  .is-active {
    background: black;
    color: #fff
  }

}
