@ulaval-red : #e30513;
@ulaval-gold : #ffc103;
@pale-grey : #cccccc;
@dark-grey: #4b4b4b;
@male-blue: #3F33FF;
@female-pink: #FF33C4;
@unset-gender: #33cc33;

.userCard{
    border:solid 1px black;
    height: calc(100% - 30px);
    margin: 15px 0 15px 0!important;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;

    .redColor{
        color:red;
    }
    .row{
        margin-top: 10px;

        &.commentsRow{
            flex:1;
        }

        &.AdminCommentsRow{
            border-top:dashed 1px black;
            padding-top:10px;

            .adminCommentsTitle{
                color:blue;
            }

            .adminComments{

            }
        }

        ul{
            list-style-type: disc;
            margin-left: 0px;

            li{
                margin:0;
            }
        }

        &.userHeader{
            margin-top: 0px;
            padding:5px 10px;
            background-color: @dark-grey;
            color:white;
            &.selected{
                background-color: @ulaval-red;
                color:white
            }


            .userName{
                font-weight: bold;
                display: block;
                width: 100%;
            }
            .userFaculty{
                font-style: italic;
                display: block;
                width: 100%;
            }
            .userCountry{
                display: block;
                width: 100%;
            }
            .userRegisteredOn, .userAvailableOn, .firstSession{
                display: block;
                width: 100%;
                font-size: 14px;
                font-style: italic;
                text-align: right;
            }
            .userRegisteredOn *, .userAvailableOn *{
                margin-left:5px;
                font-weight: bold;
            }

            div.sponsorLoad{
                display: block;
                font-size:12px;
                background-color: @pale-grey;
                color: @dark-grey;
                width: 60px;

                span.slashBar{
                    font-size: 20px;
                    font-weight: bold;
                    padding-bottom: 20px;
                    padding: 0 1px;
                }
                span.valueCurrentLoad{
                    font-size: 20px;

                    &.overLoaded{
                        color:red;
                    }
                }
                span.totalCurrentLoad{
                    font-size: 20px;
                }
            }

        }
        .sponsorLoadNames {
            margin-top:5px;
            margin-left:5px;
            padding:7px 15px;
            font-size:12px;
        }

        &.userFooter{
            padding:5px 10px;
            background-color: @pale-grey;
            text-align: right;

            &.selected{
                background-color: @ulaval-gold;
                color:black
            }
            .userDetailsData{
                font-size: 85%;
            }
            .badge{
                .userScoreTitle{
                    background-color: white;
                }
            }
            .userScore{
                color:white;
                background-color: @ulaval-red;
                border-radius: 5px;
                padding:2px 5px;
                margin:0 5px;
            }
            .scoreDisplay{
                margin-top:5px;
            }
        }
    }


    .icon {
        width:45px;
        height:45px;
        margin-bottom: 5px;
        border-radius:8px;
        background-size: contain;
        background-position: center;
        border-radius:8px;
        margin-top: 5px;

        &.genderIconMale{
            background: url("images/GenderM-White2.png") @male-blue no-repeat;
            background-size: 100% 100%;
        }
        &.genderIconFemale{
            background: url("images/GenderF-White2.png") @female-pink no-repeat;
            background-size: 100% 100%;
        }
        &.genderIconUnset{
            background: url("images/GenderO-White2.png") @unset-gender no-repeat;
            background-size: 100% 100%;
        }
        &.veteranIcon{
            background: url("images/Veteran.png") @unset-gender no-repeat;
            background-size: 100% 100%;
        }
        &.langIcon{
            background: url("images/Language.png") no-repeat;
        }
        &.prefsIcon{
            background: url("images/Preferences.png") no-repeat;
        }
        &.interestsIcon{
            background: url("images/Interests.png") no-repeat;
        }
        &.commentsIcon{
            background: url("images/Interests.png") no-repeat;
        }

    }
    .rowTitle{
        padding-right: 5px;
        font-weight: bold;
    }
}

