/* Basic editor styles */
.tiptap {
  border: 1px solid black;
}
.tiptap,
.tiptapPreview {
  margin: 10px 0;
  padding: 5px 10px;
}
.tiptap > * + *,
.tiptapPreview > * + * {
  margin-top: 0.75em;
}
.tiptap p.is-editor-empty:first-child::before,
.tiptapPreview p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.tiptap ul,
.tiptapPreview ul,
.tiptap ol,
.tiptapPreview ol {
  padding: 0 1rem;
}
.tiptap h1,
.tiptapPreview h1,
.tiptap h2,
.tiptapPreview h2,
.tiptap h3,
.tiptapPreview h3 {
  margin-top: 0!important;
  margin-bottom: 5px!important;
  line-height: 1.1;
}
.tiptap code,
.tiptapPreview code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.tiptap pre,
.tiptapPreview pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.tiptap pre code,
.tiptapPreview pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.tiptap img,
.tiptapPreview img {
  max-width: 100%;
  height: auto;
}
.tiptap blockquote,
.tiptapPreview blockquote {
  padding-left: 1rem;
  padding-top: 3px;
  padding-bottom: 3px;
  border-left: 2px solid #656565;
  background-color: #f0f0f0;
  color: #656565;
}
.tiptap blockquote p,
.tiptapPreview blockquote p {
  margin-bottom: 0!important;
}
.tiptap hr,
.tiptapPreview hr {
  border: none;
  border-top: 2px solid #656565;
  margin: 2rem 0;
}
