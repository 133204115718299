div.InfoBoxContent{
  background-color: #fff;
  color:#000;
  padding:25px;
  margin-bottom: 30px;
  position: relative;
  display: block;

  div.Block-Content{
    display: flex;
    flex-direction: column;

    .title{
      margin-bottom: 10px;
      color:#000;
      font-weight: bold;
      display: block;
      text-align: left;
      text-transform: uppercase;
    }
    div.content{
      text-align: left;

      p{margin-bottom: 10px;}

    }

    div.Block-Icon{
      margin-top:20px;
      text-align: left;

      a.linkIcon{
        background-color:#000 ;
        color:#fff;
        padding:4px 20px;
        font-size:19px;
        text-decoration: none;

        i{
          font-weight: bold;
          &+span{
            margin-left:5px;
          }
        }
        span{
          font-size:16px;
          margin-right:5px;
          position: relative;
          bottom:2px;
        }

      }
    }

  }



}