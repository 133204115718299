body {
  font-family: "Source Sans 3", "Source Sans Pro", SourceSansPro, -apple-system, Roboto, Segoe UI, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
}
body h1 {
  font-size: 1.75em;
  margin-bottom: 1.25em;
}
body h2 {
  font-size: 1.66em;
  margin-bottom: 1em;
}
body h3 {
  font-size: 1.4em;
  margin-bottom: 1em;
}
body .main-content-container {
  margin-top: 50px;
  margin-bottom: 50px;
}
body .main-content-container p {
  margin: 0 0 0.75em 0 !important;
}
body div.nav-pills {
  background-color: #000;
}
body div.nav-pills div.nav-item a {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 0;
  text-decoration: none;
  font-size: 14px;
}
body div.nav-pills div.nav-item a.nav-link.active {
  background-color: #7B747B;
  color: #fff;
}
body div.noteInputField {
  margin-top: 10px;
  color: #e30513;
  font-size: 12px;
}
body .react-confirm-alert-blur-custom-ui {
  padding: 30px;
  background-color: #fff;
  text-align: center;
  border: solid 1px black;
  font-family: "Source Sans 3", "Source Sans Pro", SourceSansPro, -apple-system, Roboto, Segoe UI, "Helvetica Neue", Arial, sans-serif;
}
body .react-confirm-alert-blur-custom-ui.smallWindow {
  max-width: 540px;
}
body .react-confirm-alert-blur-custom-ui .bigMessage {
  font-size: 1.5em !important;
}
body .react-confirm-alert-blur-custom-ui h1 {
  margin-bottom: 0.5em;
}
body .react-confirm-alert-blur-custom-ui button {
  padding: 10px 50px;
  margin-right: 30px;
}
body .react-confirm-alert-blur-custom-ui button.redButton {
  border: none;
  background-color: #e30513 !important;
  color: #fff;
}
body .react-confirm-alert-blur-custom-ui button.redButton:hover {
  background-color: #85150d !important;
  border: none !important;
  color: #fff !important;
}
body button.btn.btn-primary,
body button.btn.btn-success {
  border-radius: 0;
  background-color: #327ABD !important;
  border: solid 1px black!important;
}
body button.btn.btn-primary:hover,
body button.btn.btn-success:hover {
  border: solid 1px #327ABD !important;
  background-color: #fff !important;
  color: #327ABD !important;
}
body button.btn.btn-danger {
  border-radius: 0;
  background-color: #e30513 !important;
  border: solid 1px black!important;
}
body button.btn.btn-danger:hover {
  border: solid 1px #e30513 !important;
  background-color: #fff !important;
  color: #e30513 !important;
}
body button.btn.btn-black {
  border-radius: 0;
  background-color: #000 !important;
  border: solid 1px black!important;
  color: #fff;
}
body button.btn.btn-black:hover {
  border: solid 1px #000 !important;
  background-color: #fff !important;
  color: #000 !important;
}
body .RichTextEditor__root___2QXK- {
  font-family: "Source Sans 3", "Source Sans Pro", SourceSansPro, -apple-system, Roboto, Segoe UI, "Helvetica Neue", Arial, sans-serif;
}
