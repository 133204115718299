body{
  font-family: "Source Sans 3", "Source Sans Pro", SourceSansPro, -apple-system, Roboto, Segoe UI, "Helvetica Neue", Arial, sans-serif;
  font-size:18px;

  h1{
    font-size: 1.75em;
    margin-bottom:1.25em;
  }

  h2{
    font-size: 1.66em;
    margin-bottom:1em;
  }

  h3{
    font-size: 1.4em;
    margin-bottom:1em;
  }

  .main-content-container{
    margin-top:50px;
    margin-bottom: 50px;

    p{
      margin: 0 0 0.75em 0!important;
    }

  }


  div.nav-pills{
    background-color: #000;
    //width: max-content;

    div.nav-item{



      a{
        background-color: #000;
        color:#fff;
        padding: 10px 20px;
        border-radius: 0;
        //border-left:solid 1px white;
        text-decoration: none;
        font-size:14px;

        &.nav-link.active{
          background-color: #7B747B;
          //border-left:solid 5px #e30513;
          //border-bottom:solid 1px #e30513;
          color:#fff;
        }
      }



    }
  }

  div.noteInputField{
    margin-top: 10px;
    color: #e30513;
    font-size: 12px;
  }


.react-confirm-alert-blur-custom-ui{
  padding:30px;
  background-color: #fff;
  text-align: center;
  border: solid 1px black;
  font-family: "Source Sans 3", "Source Sans Pro", SourceSansPro, -apple-system, Roboto, Segoe UI, "Helvetica Neue", Arial, sans-serif;

  &.smallWindow{
    max-width: 540px;
  }
  .bigMessage{
    font-size:1.5em!important;
  }

  h1{
    margin-bottom: 0.5em;
  }

  button{
    padding: 10px 50px;
    margin-right: 30px;

    &.redButton{
      border:none;
      background-color: #e30513 !important;
      color:#fff;

      &:hover{
        background-color: #85150d!important;
        border: none !important;
        color: #fff !important;
      }
    }
  }

}


button.btn{
  &.btn-primary, &.btn-success{
    border-radius: 0;
    background-color: #327ABD!important;
    border:solid 1px black!important;

    &:hover{
      border:solid 1px #327ABD!important;
      background-color: #fff!important;
      color:#327ABD!important;
    }
  }

  &.btn-danger{
    border-radius: 0;
    background-color: #e30513!important;
    border:solid 1px black!important;

    &:hover{
      border:solid 1px #e30513!important;
      background-color: #fff!important;
      color:#e30513!important;
    }
  }


  &.btn-black{
    border-radius: 0;
    background-color: #000!important;
    border:solid 1px black!important;
    color:#fff;

    &:hover{
      border:solid 1px #000!important;
      background-color: #fff!important;
      color:#000!important;
    }
  }


}




  .RichTextEditor__root___2QXK-{
    font-family: "Source Sans 3", "Source Sans Pro", SourceSansPro, -apple-system, Roboto, Segoe UI, "Helvetica Neue", Arial, sans-serif;
  }


}