.EnglishLabel, .FrenchLabel{
  font-weight: bold;
  margin-bottom:10px;
  margin-top:10px;
  display: block;
  padding-left:40px;
}

.FrenchLabel{
  background-image: url("images/fr.png");
  background-repeat: no-repeat;
  background-size: 30px 20px;
  height: 20px;
  padding-top: 2px;
}

.EnglishLabel{
  background-image: url("images/en.png");
  background-repeat: no-repeat;
  background-size: 30px 20px;
  margin-top: 50px;
  height: 20px;
  padding-top: 2px;
}

.form-control {
  &.input-sm{
    width: 206px;
  }
}