div.loginForm {
  margin: 50px 0;
}
div.loginForm h1 {
  margin-bottom: 50px;
}
div.loginForm .leftSideLabel {
  color: #000;
  font-weight: bold;
}


