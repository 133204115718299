ul.formationPresencesSheet {
  margin: 0 0 0 0;
  padding: 10px 0;
}
ul.formationPresencesSheet li {
  margin: 0;
  padding: 3px 10px;
  list-style-type: none;
  display: table;
}
ul.formationPresencesSheet li.pair {
  background-color: #E7E7E7;
}
ul.formationPresencesSheet li.impair {
  background-color: #FFF;
}
ul.formationPresencesSheet li:hover {
  background-color: #8d8b8c;
  color: #fff;
}
ul.formationPresencesSheet li.formationDate {
  background-color: lightgreen;
}
ul.formationPresencesSheet li label {
  display: table-cell;
  width: 500px;
}
ul.formationPresencesSheet li input {
  display: table-cell;
  margin-right: 10px;
}
