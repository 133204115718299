.fichePairingClient{
  border:solid 1px black;
  padding: 20px 50px;
  margin-bottom: 30px;


  h3{
    margin-bottom: 0.5em;
    font-size: 1em;
  }
  span{
    font-size:0.70em;
  }


  .infoBlockInstructions{
    font-weight: bold;
    margin-top:10px;
    font-size: 0.7em;
  }

  .infoBlockInformations{
    margin-top:10px;
    font-size: 0.8em;
  }

  .infoBlockDelay{
    margin-top:10px;
    font-size: 0.8em;
  }

  .infoxJumelage{

    div.row {
      margin-top:20px;

      &:not(:first-of-type){

      }

    }

    h2{
      margin-bottom: 1em;
      border-bottom: solid 5px red;
      width:100%;
      display: table-cell;
    }

    .infoBlock{



      &.country{}
      &.faculte{}
      &.studyLevel{}
      &.messenger{}
      &.emailUlaval{}
      &.emailOther{}
      &.language{}
      &.gender{}
      &.age{}

    }
    span{
      display: block;
      label{
        font-weight: bold;
        margin-right: 5px;
        margin-bottom: 0;
        margin-top: 10px;
      }
    }

  }

}


