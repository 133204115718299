input.longInputField{
    width:100%;
}
input.hasUnits{
    display: inline-block;
}
span.inputUnits{
    display: inline-block;
    margin-left: 10px;
}
