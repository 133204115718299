.TiptapToolbarWrapper ::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.TiptapToolbarWrapper ::-webkit-scrollbar-track {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 8px;
  background-color: transparent;
}
.TiptapToolbarWrapper ::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 8px;
  background-color: #0000;
}
.TiptapToolbarWrapper :hover::-webkit-scrollbar-thumb {
  background-color: #0000001a;
}
.TiptapToolbarWrapper ::-webkit-scrollbar-thumb:hover {
  background-color: #00000026;
}
.TiptapToolbarWrapper ::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}
.TiptapToolbarWrapper ::-webkit-scrollbar-corner {
  background-color: transparent;
}
.TiptapToolbarWrapper button,
.TiptapToolbarWrapper input,
.TiptapToolbarWrapper select {
  font-size: inherit;
  font-family: inherit;
  color: #000;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;
}
.TiptapToolbarWrapper button[disabled],
.TiptapToolbarWrapper input[disabled],
.TiptapToolbarWrapper select[disabled] {
  opacity: 0.3;
}
.TiptapToolbarWrapper button.clearOutlinedButton {
  background-color: red;
  color: white;
}
.TiptapToolbarWrapper .tiptap:focus {
  outline: none;
}
.TiptapToolbarWrapper .is-active {
  background: black;
  color: #fff;
}
