div.WhiteBoxContent {
  background-color: #fff;
  color: #000;
  padding: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  display: block;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
div.WhiteBoxContent div.Block-Content {
  display: flex;
  flex-direction: column;
}
div.WhiteBoxContent div.Block-Content .title {
  margin-bottom: 10px;
  font-weight: bold;
  display: block;
  text-align: left;
}
div.WhiteBoxContent div.Block-Content .title.redTitle {
  color: #c20208;
}
div.WhiteBoxContent div.Block-Content .title.blackTitle {
  color: #000;
}
div.WhiteBoxContent div.Block-Content div.content {
  text-align: left;
  overflow-wrap: break-word;
}
div.WhiteBoxContent div.Block-Content div.content p {
  margin-bottom: 10px;
}
div.WhiteBoxContent div.Block-Content div.Block-Icon {
  margin-top: 30px;
  text-align: left;
}
div.WhiteBoxContent div.Block-Content div.Block-Icon a.linkIcon {
  background-color: #e30513;
  color: #fff;
  padding: 4px 20px;
  font-size: 19px;
  text-decoration: none;
  margin-right: 10px;
}
div.WhiteBoxContent div.Block-Content div.Block-Icon a.linkIcon:hover {
  background-color: #85150d;
}
div.WhiteBoxContent div.Block-Content div.Block-Icon a.linkIcon i {
  font-weight: bold;
}
div.WhiteBoxContent div.Block-Content div.Block-Icon a.linkIcon i + span {
  margin-left: 5px;
}
div.WhiteBoxContent div.Block-Content div.Block-Icon a.linkIcon span {
  font-size: 16px;
  margin-right: 5px;
  position: relative;
  bottom: 2px;
}
