span.dtLeftSideLabel{
    text-align: center;
    display:none;
}
span.dtLeftSideLabel.displayDTTitle{
    display: block;

}
label.dtLeftSideLabel.displayDTTitle{
    margin-top:28px;
}
div.dtField{
    display:inline-block;
}
