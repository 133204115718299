div.loginForm{
  margin: 50px 0;
  h1{
    margin-bottom: 50px;
  }

  .leftSideLabel{
    color: #000;
    font-weight: bold;
  }


}
