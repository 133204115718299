.link-button {
    position: relative;
    float: left;
    padding: 10px 16px;
    margin-bottom: .5em;
    line-height: 1.4375;
    text-decoration: none;
    color: #335075;
    background-color: #eaebed;
    border: 1px solid #dcdee1;
}

.pagination>li.active>button.link-button{
    z-index: 2;
    color: #fff;
    background-color: #2572b4;
    border-color: #2572b4;
    cursor: default;
}

.link-button:hover,
.link-button:focus {
    color: #335075;
    background-color: #d4d6da;
    border-color: #bbbfc5;
}
