.PopperWindowComponent{
  z-index:999
}

.PopperWindow{
  background-color: #FFF;
  border: solid #DBDBDB 2px;
  width: 100%;
  height: 100%;
  overflow: scroll;

  &:hover{
    border-color:#000;
  }



  .PopperWindow-Title{
    font-size: 1.25rem;
    font-weight: 500;
    padding:10px 20px;
    cursor: grab;
    background-color: #EBEBEB;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 21px);
    z-index: 999;

    &.Moving{
      cursor: grabbing;
    }

    &:hover{
      background-color: #DBDBDB;
    }

    .PopperWindowTitleText
    {
      display: inline-flex;
    }

    .PopperWindowOptions{
      display: inline-flex;
      text-align: right;

      button{
        &:first-of-type{
          margin-left:0;
        }

        &:hover{
          background-color: #FFC003;
          color:#444;
        }

        background-color: #444;
        color:#fff;
        border:none;
        margin-left:5px;
        padding:3px 6px;

        svg{
          width:15px;
          height:15px;
          top:-1px;
          position: relative;
        }
      }
    }

  }

  .PopperWindow-Content{
    position: relative;
    top:80px;
    padding-top:20px;
    padding-left:30px;
    padding-right:30px;
    padding-bottom:150px;
    background-color: #FFF;
    overflow: hidden;
  }

  .PopperWindow-Actions{
    position: absolute;
    width: calc(100% - 21px);
    background-color: #FFF;
    padding-left:30px;
    padding-right:30px;
    padding-top:10px;
    padding-bottom: 20px;
    border-top: dashed black 1px;
    margin-top:20px;
    text-align:right;
    bottom:2px;

    button{
      margin-left: 5px;
    }
  }



}