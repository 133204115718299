.CRUDTableOptions {
  border: solid 1px black;
}
.CRUDTableOptions .filterOptions {
  background-color: #4b4b4b;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}
.CRUDTableOptions .filterOptionsContent {
  padding: 10px;
}
.CRUDTableOptions .filterOptionsFooter {
  padding: 5px 10px;
  background-color: #cccccc;
  text-align: right;
}


