div.WhiteBoxContent{
  background-color: #fff;
  color:#000;
  padding:25px;
  margin-top:30px;
  margin-bottom: 30px;
  position: relative;
  display: block;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

  div.Block-Content{
    display: flex;
    flex-direction: column;

    .title{
      margin-bottom: 10px;
      font-weight: bold;
      display: block;
      text-align: left;

      &.redTitle{
        color:#c20208;
      }
      &.blackTitle{
        color: #000;
      }


    }
    div.content{
      text-align: left;
      overflow-wrap: break-word;
      p{margin-bottom: 10px;}

    }

    div.Block-Icon{
      margin-top:30px;
      text-align: left;

      a.linkIcon{
        background-color:#e30513 ;
        color:#fff;
        padding:4px 20px;
        font-size:19px;
        text-decoration: none;
        margin-right: 10px;

        &:hover{
          background-color: #85150d;
        }

        i{
          font-weight: bold;
          &+span{
            margin-left:5px;
          }
        }
        span{
          font-size:16px;
          margin-right:5px;
          position: relative;
          bottom:2px;
        }

      }
    }

  }



}