.CRUDTableOptions{
    border:solid 1px black;

    .filterOptions{
        background-color: #4b4b4b;
        color: white;
        padding: 10px;
        margin-bottom: 10px;
    }

    .filterOptionsContent{
        padding:10px;
    }

    .filterOptionsFooter{
        padding: 5px 10px;
        background-color: #cccccc;
        text-align: right;
    }
}