#header-sub-nav-main{

      ul.menu{
        position: relative;
            li, li.has-children {

              a.is-active {
                background-color: #7B747B;
                color: #fff;
              }

              &:hover{
                color:black;

                a {
                  color:black;
                  background-color: #fff;

                  outline-color: #000;
                  outline-offset: -2px;
                  outline-style: solid;
                  outline-width: 1px;


                }
                a.is-active {
                  color: black;
                }

                ul{
                  display:block;
                  outline-color: #000;
                  outline-offset: -1px;
                  outline-style: solid;
                  outline-width: 1px;

                  li{



                    a{
                      outline:none;
                      display: block;
                      padding:5px 25px 5px 10px;
                      text-decoration: none;
                      font-size:14px;
                      &:hover{
                        background-color: #8d8b8c;
                        color:#fff;
                      }
                    }
                  }
                }

              }

              ul{
                display: none;
                position: absolute;
                background-color: #fff;
                margin:0;
                padding:0px ;
                width: max-content;

                li{
                  a{
                    display: block;
                    text-decoration: none;
                    color:#AAA;


                  }
                  &:hover{
                    color:#FFF;
                    background-color: #7B747B;
                  }
                }

              }


            }


        }
}


