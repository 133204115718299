.hideUserConfirmationButton {
  display: none;
}
h1.userListTitle {
  font-size: 24px;
  margin: 30px 0;
}
svg.lockIconLeft {
  margin-left: 15px;
  width: 40px;
  height: 40px;
  clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
}
svg.lockIconRight {
  margin-left: 15px;
  width: 40px;
  height: 40px;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
}
svg.lockIconFull {
  margin-left: 15px;
  width: 40px;
  height: 40px;
}
.editAndMatchButton {
  color: #e2e0e2;
  background-color: transparent;
  border-color: transparent;
  width: 32px;
  padding: 0px;
}
.editAndMatchButton img {
  width: 30px;
}
