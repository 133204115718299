.containerfilterSelectionMenuBox{
    border:solid 1px black;
    padding-bottom: 20px;

    input.limitSearchResults{width: 50px;}

    &:not(:first-child){
        padding:0 10px;
    }
    & > .row:not(:first-child){ padding-left:10px;padding-top: 5px}
    .row:not(:first-child){padding-top: 5px}

    .filterSelectionMenuTitle{
        background-color: #4b4b4b;
        color:white;
        padding:10px;
        margin-bottom: 10px;
    }
    .filterSelectionMenuFooter{
        margin-top:20px;
    }
}

div.currentSelected{
    border:solid 1px black;
    margin-right:10px;

    .cardTitle{
        padding:5px 10px;
        background-color: #ce0202;
        color:#fff;
        text-align: center;
    }
    .cardData{
        padding:10px;

        .nameSelectedSponsor{
            display: block;
            width:100%;
            font-weight: bold;
        }
        .nameSelectedSponsorFaculty{
            display: block;
            width:100%;
            font-style: italic;
        }
    }
}