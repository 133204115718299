.fichePairingClient {
  border: solid 1px black;
  padding: 20px 50px;
  margin-bottom: 30px;
}
.fichePairingClient h3 {
  margin-bottom: 0.5em;
  font-size: 1em;
}
.fichePairingClient span {
  font-size: 0.7em;
}
.fichePairingClient .infoBlockInstructions {
  font-weight: bold;
  margin-top: 10px;
  font-size: 0.7em;
}
.fichePairingClient .infoBlockInformations {
  margin-top: 10px;
  font-size: 0.8em;
}
.fichePairingClient .infoBlockDelay {
  margin-top: 10px;
  font-size: 0.8em;
}
.fichePairingClient .infoxJumelage div.row {
  margin-top: 20px;
}
.fichePairingClient .infoxJumelage h2 {
  margin-bottom: 1em;
  border-bottom: solid 5px red;
  width: 100%;
  display: table-cell;
}
.fichePairingClient .infoxJumelage span {
  display: block;
}
.fichePairingClient .infoxJumelage span label {
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 0;
  margin-top: 10px;
}
