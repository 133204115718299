div.GreyBoxContent {
  background-color: #d9d9d9;
  color: #000;
  padding: 25px;
  margin-bottom: 30px;
  position: relative;
  display: block;
}
div.GreyBoxContent div.Block-Content {
  display: flex;
  flex-direction: column;
}
div.GreyBoxContent div.Block-Content .title {
  margin-bottom: 10px;
  font-weight: bold;
  display: block;
  text-align: left;
}
div.GreyBoxContent div.Block-Content .title.redTitle {
  color: #c20208;
}
div.GreyBoxContent div.Block-Content .title.blackTitle {
  color: #000;
}
div.GreyBoxContent div.Block-Content div.content {
  text-align: left;
}
div.GreyBoxContent div.Block-Content div.content p {
  margin-bottom: 10px;
}
div.GreyBoxContent div.Block-Content div.Block-Icon {
  margin-top: 20px;
  text-align: left;
}
div.GreyBoxContent div.Block-Content div.Block-Icon a.linkIcon {
  background-color: #111;
  color: #fff;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 19px;
}
@media only screen and (max-width: 992px) {
  div.GreyBoxContent div.Block-Content div.Block-Icon a.linkIcon {
    display: block;
    padding: 7px 7px 11px 15px;
    width: 70%;
    margin-bottom: 10px;
  }
  div.GreyBoxContent div.Block-Content div.Block-Icon span {
    font-size: 16px;
    position: relative;
  }
}
@media only screen and (min-width: 993px) {
  div.GreyBoxContent div.Block-Content div.Block-Icon a.linkIcon {
    padding: 4px 20px;
    margin-right: 10px;
  }
  div.GreyBoxContent div.Block-Content div.Block-Icon a.linkIcon i {
    font-weight: bold;
  }
  div.GreyBoxContent div.Block-Content div.Block-Icon a.linkIcon i + span {
    margin-left: 5px;
  }
  div.GreyBoxContent div.Block-Content div.Block-Icon a.linkIcon span {
    font-size: 16px;
    margin-right: 5px;
    position: relative;
    bottom: 2px;
  }
}
