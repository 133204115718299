label.timeOnlyLabel{
    margin-top:5px;
}
span.timeLeftSideLabel{
    text-align: left;
    margin-left:10px;
    display:none;
}
span.timeLeftSideLabel.displayDateTitle{
    display: block;

}
label.timeOnlyLabel.displayDateTitle{
    margin-top:28px;
}
div.timeField{
    display:inline-block;
}
.timeOnlyField{
    width:206px;
}