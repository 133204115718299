ul.formationPresencesSheet{
  margin:0 0 0 0;
  padding:10px 0;

  li{
    margin:0;
    padding: 3px 10px;
    list-style-type: none;
    display:table;

    &.pair{background-color: #E7E7E7}
    &.impair{background-color: #FFF}
    &:hover{background-color: #8d8b8c;color:#fff}
    &.formationDate{
      background-color: lightgreen;
    }

    label{display: table-cell;width: 500px;}
    input{display: table-cell;margin-right:10px;}
  }
}



div.formationFooter{
}