@desktop:   ~"only screen and (min-width: 993px)";
@tablet:    ~"only screen and (max-width: 992px)";

@media @desktop {
  .mobile-only{
    display:none;
  }
}

@media @tablet {
  .mobile-only{
    display:block;
  }

  div.headerImage{
    display:none;
    margin-bottom: 30px;

    img{
      max-width: 100%;
      height: auto;
    }
  }

  div.clientCard{
    margin-bottom: 5px;
  }

}




h1.homepageTitle{
  margin-bottom:50px;
}

div.loginCards{
  display:flex;
  align-items: stretch;
  margin-bottom: 30px;
}

div.headerImage{
  margin-bottom: 30px;
  img{
    max-width: 100%;
    height: auto;
  }
}